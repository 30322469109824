import * as React from "react"
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/Layout'
import { FaInfoCircle } from 'react-icons/fa'
import { FaList } from 'react-icons/fa'
import { FaPenAlt } from 'react-icons/fa'

const styles = require('./Index.module.css')  
const config = require('../../data/siteConfig')
const logo = config.siteLogo 

const IndexPage = () => {
    return (
      <Layout>
        <div className = "page">
          <Helmet  defer={false}>
                <title>{config.siteTitle}</title>
                <link rel="canonical" href={`${config.siteUrl}`} />
          </Helmet>
          <div className={`${styles.pageContent} ${styles.homePage} pageContent`}>
          <img 
              src={logo}
              className = "center-logo" 
              alt={config.siteTitle}
              />
            <h1>{config.siteTitle}</h1>
            <div className={`${styles.mainLinks} mainLinks`}>
              <Link to="/about/" className="mainLink"><p><FaInfoCircle/></p>Об издании</Link>
              <Link to="/commentary/" className="mainLink"><p><FaList/></p>Комментарии к Евангелию</Link>
              <Link to="/articles/" className="mainLink"><p><FaPenAlt/></p>Лекции и Статьи</Link>
            </div> 
  
          </div>
        </div>
      </Layout>
    )
  }
  export default IndexPage;
  /*
interface IndexPageProps {
    data: {
        site:{
            siteMetadata: {
                siteUrl: string;
                title: string;
            }
        }
    }
}



export default class IndexPage extends 
React.Component<IndexPageProps, {}>  {

    public render () {
        return (
            <div className={styles.Container}>
             <h1> Title</h1>
           </div>
        )
    }
}
*/
/*
export const indexPageQuery = graphql`
    query IndexPageQuery {
        site{
            siteMetadata {
                siteUrl
                title
                description
            }
        }
    }
`
*/